.center-col-team {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.our-team-text {
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #403f52;
}
.our-team-text1 {
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 5rem;
  color: #6f7581;
}
.our-team-text11 {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}
.card-width {
  width: 30rem;
}
.name-team {
  font-size: 1.8rem;
  font-weight: 600;
  color: #403f52;
}
.detail-team {
  font-size: 2rem;
  font-weight: 800;
  color: #6f7581;
}
