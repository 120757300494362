.mainSmartAssistant {
  padding: 30px 0px;
}
.centersmart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.logosmart {
  width: 40rem;
}
.logosmart1 {
  width: 50rem;
}
.centersmart22 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10rem;
}
.smarttext {
  color: #6f7581;
  font-size: 1.8rem;
  font-weight: 700;
}
.planttext {
  font-size: 3.7rem;
  font-weight: 700;
  color: #403f52;
}
.planttext44 {
  font-size: 3.7rem;
  font-weight: 700;
  color: #403f52;
  text-align: center;
  margin-bottom: 5rem;
}
.planttext1 {
  color: #6f7581;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 25px;
}

@media (max-width: 1024px) {
  .smarttext {
    color: #6f7581;
    font-size: 1.4rem;
    font-weight: 700;
  }
  .planttext {
    font-size: 3rem;
    font-weight: 700;
    color: #403f52;
  }
  .planttext1 {
    color: #6f7581;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 25px;
  }
  .img-fluid {
    width: 100%;
  }
  .centersmart22 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 8rem;
  }
}
@media (max-width: 768px) {
  .planttext1 {
    color: #6f7581;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 25px;
  }
}
@media (max-width: 510px) {
  .smarttext {
    color: #6f7581;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
  }
  .planttext {
    font-size: 3rem;
    font-weight: 700;
    color: #403f52;
    text-align: center;
  }
  .planttext1 {
    color: #6f7581;
    font-size: 1.3rem;
    font-weight: 600;
    width: 40rem;
    line-height: 20px;
    text-align: center;
  }
  .rowmargin {
    flex-direction: column-reverse !important;
  }
}
