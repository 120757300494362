.whatsapp-button {
  position: fixed; /* Keeps the button fixed even when scrolling */
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  z-index: 2;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

.whatsapp-icon {
  width: 35px;
  height: 35px;
  filter: brightness(0) invert(1); /* Makes the icon white */
}
