.centercard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 0px;
}
.cardwidth {
  width: 40rem;
}
.prottext {
  color: #160e0e !important;
  font-size: 4rem !important;
  font-weight: 700 !important;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 4rem;
}
.ant-card-meta-title {
  color: #160e0e !important;
  font-size: 1.8rem !important;
  font-weight: 700 !important;
}
.ant-card-meta-description {
  color: #6f7581 !important;
  font-size: 1.5rem !important;
  font-weight: 400 !important;
  line-height: 33px !important;
}
@media (max-width: 1024px) {
  .cardwidth {
    width: 30rem;
  }
}
@media (max-width: 768px) {
  .cardwidth {
    width: 30rem;
  }
  .ant-card-meta-description {
    color: #6f7581 !important;
    font-size: 1.5rem !important;
    font-weight: 400 !important;
    line-height: 20px !important;
  }
}
