.centeremail {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mainemail {
  padding: 30px 0px;
}
.mailtext {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #403f52;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
.mailtext44 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #403f52;
  font-size: 3.7rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5rem;
}
.mailtext1 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #6f7581;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
}
.icon {
  font-size: 4rem;
  color: #7268ff !important;
  font-weight: 400 !important;
  margin-bottom: 3rem !important;
  margin-top: 1rem;
}
@media (max-width: 1024px) {
  .mailtext1 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: #6f7581;
    font-size: 1.1rem;
    font-weight: 400;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .mailtext1 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: #6f7581;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
  }
}
