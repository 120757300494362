* {
  margin: 0;
  padding: 0;
  font-family: "Nunito" !important;
}

html {
  font-size: 62.5%;
}

@media (max-width: 998px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 45%;
  }
}
