.menuitemsbutton {
  font-size: 1.2rem !important;
  font-weight: 900 !important;
  letter-spacing: normal !important;
  text-align: left !important;
}

.logopic {
  width: 22rem;
  padding: 1.25rem;
}
