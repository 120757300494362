.maindivofherosection {
  height: 45rem;
  background-color: #030637;
  padding-top: 5rem;
}

/* .textcontainer {
  padding-left: 12rem;
  padding-top: 8rem;
  width: 50rem;
} */
.textcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.slogan {
  color: #b3ccff;
  font-size: 1.8rem;
  font-weight: 700;
}

.slogandescription {
  color: #fff;
  font-size: 3.7rem;
  font-weight: 700;
}

.detailsdescription {
  color: #b3ccff;
  font-size: 1.8rem;
}

.explorebutton {
  background-color: #ff6500 !important;
  color: white !important;
  padding: 2rem !important;
  font-weight: 700 !important;
  font-size: 1.3rem !important;
  border-radius: 3rem !important;
  margin-top: 2rem !important;
}

.calenderimg {
  position: absolute;
  right: 0;
  margin-top: 5rem;
  z-index: 0;
}

.deskimage {
  position: absolute;
  margin-top: 26rem;
  z-index: 1;
  right: 0;
  margin-right: 5rem;
}

.girlimage {
  position: absolute;
  margin-top: 27rem;
  z-index: 2;
  right: 0;
  margin-right: 27rem;
}

.flowerimage {
  position: absolute;
  margin-top: 35rem;
  z-index: 2;
  right: 0;
  margin-right: 55rem;
}

@media (max-width: 1030px) {
  .calenderimg {
    position: absolute;
    right: 0;
    margin-top: 5rem;
    z-index: 0;
    height: 30rem;
  }

  .deskimage {
    position: absolute;
    margin-top: 26rem;
    z-index: 1;
    right: 0;
    margin-right: 5rem;
    height: 30rem;
  }

  .girlimage {
    position: absolute;
    margin-top: 27rem;
    z-index: 2;
    right: 0;
    margin-right: 27rem;
    height: 30rem;
  }

  .flowerimage {
    position: absolute;
    margin-top: 38rem;
    z-index: 2;
    right: 0;
    margin-right: 43rem;
    height: 12rem;
  }
}

@media (max-width: 780px) {
  .calenderimg {
    position: absolute;
    right: 0;
    margin-top: 5rem;
    z-index: 0;
    height: 30rem;
  }

  .deskimage {
    position: absolute;
    margin-top: 26rem;
    z-index: 1;
    right: 0;
    margin-right: 5rem;
    height: 30rem;
  }

  .girlimage {
    position: absolute;
    margin-top: 27rem;
    z-index: 2;
    right: 0;
    margin-right: 27rem;
    height: 30rem;
  }

  .flowerimage {
    position: absolute;
    margin-top: 38rem;
    z-index: 2;
    right: 0;
    margin-right: 45rem;
    height: 15rem;
  }
}

@media (max-width: 440px) {
  .mobileresponsive {
    display: flex;
    justify-content: center;
  }
  .calenderimg {
    display: none !important;
  }

  .deskimage {
    display: none !important;
  }

  .girlimage {
    display: none !important;
  }

  .flowerimage {
    display: none !important;
  }
  .slogandescription {
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
  }

  .detailsdescription {
    color: #b3ccff;
    font-size: 1.8rem;
  }
}

@media (max-width: 330px) {
  .maindivofherosection {
    height: 60rem;
    background-color: #4033ff;
    padding-top: 5rem;
  }
}
