.mainfooter {
  padding: 50px 0px;
  background-color: #f8f8ff;
}
.centerfooter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.textexpo {
  color: #403f52;
  font-size: 2rem;
  font-weight: 700;
}
.textexpo1 {
  font-size: 1.5rem;
  color: #848496;
  font-weight: 400;
}
.textfollow {
  color: #403f52;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.iconantddd {
  font-size: 2rem !important;
  margin-left: 1rem;
  color: #403f52 !important;
  margin-top: 2rem;
}
.footerbottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  color: #a1a1a1;
  font-size: 1.4rem;
  font-weight: 500;
}
.footerimg {
  width: 20rem;
}
